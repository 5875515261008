import React from "react"
import Section from "~common/components/Section"
import Plus from "./components/Plus"

const WhatMakesItSpecial = () => {
  return (
    <Section color="secondary" bg="primary">
      <Section.Header color="secondary" bg="primary">
        What makes it special
      </Section.Header>
      <Section.Body
        sx={{
          px: [5, "9%", "9%", "16%"],
          py: [5, 6],
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr"],
          gridGap: [4, 5, 4, 5],
        }}
      >
        <Plus>
          <Plus.Title>A CHANGE of PERSPECTIVE</Plus.Title>
          <Plus.Description>
            Get a deeper feel of the universe by looking at the Solar System
            from different points of view.
          </Plus.Description>
          <Plus.Icon name="almagesto-achangeofperspective" />
        </Plus>
        <Plus>
          <Plus.Title>Choose ANY EPOCH</Plus.Title>
          <Plus.Description>
            You are free to explore the Solar System during any time span.
          </Plus.Description>
          <Plus.Icon name="almagesto-chooseanyepoch" />
        </Plus>
        <Plus>
          <Plus.Title>8 LEVELS of ZOOM</Plus.Title>
          <Plus.Description>
            Explore the Solar System at different scales.
          </Plus.Description>
          <Plus.Icon name="almagesto-8levelsofzoom" />
        </Plus>
        <Plus>
          <Plus.Title>GRAPHIC &amp; THEMES</Plus.Title>
          <Plus.Description>
            Different themes let you customize your personal astronomy poster.
          </Plus.Description>
          <Plus.Icon name="almagesto-graphicandthemes" />
        </Plus>
        <Plus>
          <Plus.Title>NASA / JPL ephemerides</Plus.Title>
          <Plus.Description>
            Ephemerides built and published by the Jet Propulsion Laboratory at
            NASA.
          </Plus.Description>
          <Plus.Icon name="almagesto-nasajplephemerides" />
        </Plus>
        <Plus>
          <Plus.Title>Astronomically ACCURATE</Plus.Title>
          <Plus.Description>
            One of the few posters that completely respects the Solar System
            scale.
          </Plus.Description>
          <Plus.Icon name="almagesto-astronomicallyaccurate" />
        </Plus>
        <Plus>
          <Plus.Title>STATE-OF-THE-ART SOFTWARE</Plus.Title>
          <Plus.Description>
            Built on top of the best libraries used by the astrophysics
            community.
          </Plus.Description>
          <Plus.Icon name="almagesto-stateoftheartsoftware" />
        </Plus>
        <Plus>
          <Plus.Title>SCIENCE meets DESIGN</Plus.Title>
          <Plus.Description>
            A finely designed piece of wall art that doesn't sacrifies
            scientific accuracy.
          </Plus.Description>
          <Plus.Icon name="almagesto-sciencemeetsdesign" />
        </Plus>
        <Plus>
          <Plus.Title>FINE DETAILS</Plus.Title>
          <Plus.Description>
            A minimal poster designed to ensure optimal readability and a clear
            design.
          </Plus.Description>
          <Plus.Icon name="almagesto-finedetails" />
        </Plus>
        <Plus sx={{ gridColumn: ["auto", "auto", 2] }}>
          <Plus.Title>CULTURAL VALUE</Plus.Title>
          <Plus.Description>
            A way to celebrate science history and the civilizations connected
            by the Almagest.
          </Plus.Description>
          <Plus.Icon name="almagesto-culturalvalue" />
        </Plus>
      </Section.Body>
      <Section.Footer logo nasaBadge color="secondary" />
    </Section>
  )
}

export default WhatMakesItSpecial
